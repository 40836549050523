@tailwind base;
@tailwind components;
@tailwind utilities;

.tippy-box[data-theme~="navy-blue"] {
  @apply bg-navyblue-900 text-gray-50 rounded;
}

.tippy-box[data-theme~="navy-blue"][data-placement^="bottom"]
  > .tippy-arrow::before {
  @apply border-b-navyblue-900;
}

.tippy-box[data-theme~="white"] {
  @apply bg-gray-50 text-navyblue-900 rounded;
}

.tippy-box[data-theme~="white"][data-placement^="bottom"]
  > .tippy-arrow::before {
  @apply border-b-gray-50;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply w-2;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-primary rounded-2xl;
}

.loader div {
  animation-duration: 0.6s;
}

.loader div:first-child {
  animation-delay: 0.1s;
}

.loader div:nth-child(2) {
  animation-delay: 0.3s;
}

.loader div:nth-child(3) {
  animation-delay: 0.6s;
}

.heart {
  @apply p-1 rounded-full;
}

.heart:hover {
  @apply bg-red-100;
}

.heart[liked="true"] > svg {
  @apply fill-red-600;
}
.heart[liked="false"] > svg {
  @apply fill-transparent;
}
